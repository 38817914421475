import React, { MutableRefObject, useEffect, useState } from 'react';
import keycloak from 'core/config/keycloak';
import { CategoriaSolucoesPEService } from 'core/http/service/acessosExclusivos/CategoriaSolucoesPE.service';
import useFetch from 'hooks/useFetch';
import TipoSolucoes from 'models/solucoes/TipoSolucoes';
import Styled from './OfferedSolutions.styled';
import solutionsInfo from './MultiSolucoes';
import ListCards from './../../constants/ListCartoes';
import { TagSolucoesEnum, translateEnum } from 'enums/TagSolucoesEnum';
import Colors from 'enums/Colors';
import { PrimaryButton } from 'components/UI/Buttons';
import { IsMobileBiggerWidth } from 'core/util/WindowSize';
import { useHistory } from 'react-router-dom';
import { ButtonHomeV2 } from 'pages/home/components/homeV2/button/ButtonHomeV2.styled';

interface IOfferedSolutions {
    ref: MutableRefObject<HTMLDivElement | null>;
}

const OfferedSolutions = ({ ref }: IOfferedSolutions) => {
    const [solucoesExclusivas, setSolucoesExclusivas] = useState<TipoSolucoes[]>([]);
    const serviceCategoriaSolucoes = new CategoriaSolucoesPEService();
    const isMobile: boolean = IsMobileBiggerWidth();
    const history = useHistory();

    useFetch(async () => {
        if (keycloak.authenticated) {
            const response = await serviceCategoriaSolucoes.getSolucoesByUser();

            if (response.status === 200) {
                setSolucoesExclusivas(response.data);
            }
        }
    }, []);

    return (
        <>
            <Styled.Container ref={ref} id="solucoes">
                <Styled.Content>
                    {ListCards.map((card, index) => (
                        <Styled.CardSolution isMobile={isMobile} key={index}>
                            {!isMobile && (
                                <Styled.TagContainer>
                                    {card.tag.map((tag: TagSolucoesEnum, index) => (
                                        <Styled.Tag key={index}>{translateEnum(tag)}</Styled.Tag>
                                    ))}
                                </Styled.TagContainer>
                            )}
                            <Styled.Logo img={card.img!} />
                            <Styled.CardTitle>{card.titulo}</Styled.CardTitle>
                            <Styled.CardDescription>{card.descricao}</Styled.CardDescription>
                            <Styled.ButtonContainer>
                                <ButtonHomeV2 onClick={() => history.push(card.link)}>Saiba mais</ButtonHomeV2>
                                {solucoesExclusivas.filter(f => f.id === card.id).length > 0 && (
                                    <PrimaryButton
                                        style={{
                                            padding: '10px 10px 10px 10px',
                                            borderRadius: 4,
                                            width: 'auto',
                                            fontWeight: 500,
                                            fontSize: '15px',
                                        }}
                                        type="button"
                                        onClick={() => history.push(card.linkExclusivo)}
                                    >
                                        Acesso conteúdo exclusivo
                                    </PrimaryButton>
                                )}
                            </Styled.ButtonContainer>
                        </Styled.CardSolution>
                    ))}
                </Styled.Content>
            </Styled.Container>
        </>
    );
};

export default OfferedSolutions;
