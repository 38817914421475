import { FerramentaAutoriaContext } from 'pages/ferramentaAutoria/context/FerramentaAutoriaContext';
import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import FadConentMenuLateralPublic from './components/FadConentMenuLateralPublic';
import { ComponentInteractionEnum } from 'enums/ComponentInteractionEnum';
import RecursoMediaSwitch from 'pages/recursos/recursos-conteudo/components/media-switch/RecursoMediaSwitch';
import { Collapse, ConfigProvider, Image, Modal, Progress, ProgressProps } from 'antd';
import Colors from 'enums/Colors';
import QuizInteractionPublic from 'pages/recursos/recursos-conteudo/components/quiz/QuizInteractionPublic';
import CarrosselInteractionPublic from 'pages/recursos/recursos-conteudo/components/carrossel/CarrosselInteractionPublic';
import ComponentAbasPublic from 'pages/recursos/recursos-conteudo/components/abas/ComponentAbasPublic';
import ComponentBaralhoPublic from 'pages/recursos/recursos-conteudo/components/baralho/ComponentBaralhoPublic';
import ReguaAvaliativaPublic from 'pages/recursos/recursos-conteudo/components/reguaAvaliativa/ReguaAvaliativaPublic';
import ComponentTimeLinesPublic from 'pages/recursos/recursos-conteudo/components/timelines/ComponentTimeLinesPublic';
import ComponentQuizPontucaoPublic from 'pages/recursos/recursos-conteudo/components/quiz/QuizPontuacaoPublic';
import ComponentCenarioPublic from 'pages/recursos/recursos-conteudo/components/cenario/ComponentCenarioPublic';
import CorrespondentPublic from 'pages/recursos/recursos-conteudo/components/correspondent/CorrespondentPublic';
import { CaretRightOutlined } from '@ant-design/icons';
import { fetchS3ComponentInteraction, fetchStorageFileAuthoringTools } from 'hooks/StoreFiles';
import { FAD_Button_NextInformation, FAD_Button_Outline } from 'pages/ferramentaAutoria/components/FadButtons';
import { FadContentsPublicModel } from 'models/ferramentaAutoria/public/FadContentsPublicModel';
import { FadUserProgressContentsService } from 'core/http/service/ferramentaAutoria/FadUserProgressContents.service';
import { FadUserProgressContentsPublicModel } from 'models/ferramentaAutoria/public/FadUserProgressContentsPublicModel';
import { FadModulesPublicModel } from 'models/ferramentaAutoria/public/FadModulesPublicModel';
import FadRaterCommentary from 'pages/ferramentaAutoria/pages/public/components/FadRaterCommentary';
import { MdArrowDownward, MdArrowUpward } from 'react-icons/md';
import { FadUserConcluidModulesService } from 'core/http/service/ferramentaAutoria/FadUserConcluidModules.service';
import { FadUserConcluidModulesPublicModel } from 'models/ferramentaAutoria/public/FadUserConcluidModulesPublicModel';
import { useHistory, useParams } from 'react-router-dom';
import { IsCustomMaxWidth } from 'core/util/WindowSize';
import './assets/Content.css';
import ComponentInteractionModel from 'models/componentInteraction/ComponentInteraction';
import AmpliarImgV2 from 'components/ampliarImg/AmpliarImgV2';
import ComponentDividerPublic from 'pages/recursos/recursos-conteudo/components/dividers/ComponentDividerPublic';
import ComponentMarkPublic from 'pages/recursos/recursos-conteudo/components/mark/ComponentMarkPublic';
import ComponentHostpotPublic from 'pages/recursos/recursos-conteudo/components/hostpots/ComponentHostpotPublic';
import { FadAvaliationsService } from 'core/http/service/ferramentaAutoria/FadAvaliations.service';
import { FadAvaliationsModel } from 'models/ferramentaAutoria/FadAvaliationsModel';
import { FadAvaliationsComponentService } from 'core/http/service/ferramentaAutoria/FadAvaliationsComponent.service';
import { FadAvaliationsComponentModel } from 'models/ferramentaAutoria/FadAvaliationsComponentModel';
import { ButtonHomeSolftBlueV2, ButtonHomeV2 } from 'pages/home/components/homeV2/button/ButtonHomeV2.styled';
import { TbLock } from 'react-icons/tb';
import FeedBacks from 'pages/ferramentaAutoria/constants/FadCompletedMessages';
import { isProduction } from 'constants/EnvironmentData';
import { hotjar } from 'react-hotjar';

const StyledCaretRightOutlined = styled(CaretRightOutlined)``;

const Styled = {
    Container: styled.div`
        display: flex;
        width: 100%;
        padding-top: 90px;
        overflow-x: hidden;
    `,

    Contents: styled.div<{ menuOpen?: boolean }>`
        display: flex;
        flex-direction: column;

        gap: 16px;
        width: ${({ menuOpen }) => (menuOpen ? ' 80%' : ' 90%')};
        transition: all 0.5s ease-out;
        padding-left: ${({ menuOpen }) => (menuOpen ? '  90px' : '  132px')};
        transform: ${({ menuOpen }) => (menuOpen ? 'translateX(300px)' : 'translateX(40px)')};
        z-index: 0;

        @media screen and (max-width: 1600px) {
            padding-right: ${({ menuOpen }) => (menuOpen ? '  90px' : '  40px')};
        }

        @media screen and (max-width: 720px) {
            transform: ${({ menuOpen }) => (menuOpen ? 'translateX(300px)' : 'translateX(0px)')};
            width: 100%;
            padding: 16px;
        }
    `,

    RowBettween: styled.div`
        margin: 22px 0px;
        display: flex;
        justify-content: space-between;
        gap: 32px;

        @media screen and (max-width: 720px) {
            flex-direction: column;
            justify-content: start;
            gap: 16px;
        }
    `,

    TextContent: styled.p`
        font-family: 'Barlow', sans-serif !important;
        max-width: 100%;
        line-height: 1.4; /* Melhor espaçamento padrão */

        /* Ajustes para parágrafos */
        p {
            margin: 0 0 1em; /* Espaço entre parágrafos */
        }

        /* Listas */
        ol,
        ul {
            padding-left: 20px;
        }

        ol {
            list-style: inside decimal;
        }

        ul {
            list-style: inside disc;
        }

        /* Títulos */
        h1,
        h2 {
            font-weight: 700;
            margin: 0.5em 0;
        }

        h1 {
            font-size: 2em;
        }
        h2 {
            font-size: 1.5em;
        }
        h3 {
            font-size: 1.17em;
        }
        h4 {
            font-size: 1em;
        }
        h5 {
            font-size: 0.8em;
        }
        h6 {
            font-size: 0.6em;
        }

        /* Estilos do Quill */
        .ql-align-center {
            text-align: center;
        }
        .ql-align-right {
            text-align: right;
        }
        .ql-align-justify {
            text-align: justify;
        }

        .ql-bold {
            font-weight: bold;
        }
        .ql-italic {
            font-style: italic;
        }
        .ql-underline {
            text-decoration: underline;
        }
        .ql-strike {
            text-decoration: line-through;
        }

        .ql-blockquote {
            border-left: 4px solid #ccc;
            padding-left: 16px;
            margin-left: 0;
            font-style: italic;
        }

        .ql-code-block {
            background-color: #f4f4f4;
            padding: 8px;
            border-radius: 4px;
            font-family: monospace;
        }

        /* 🎨 Adicionando suporte para fontes do Quill */
        .ql-font-serif {
            font-family: serif;
        }
        .ql-font-monospace {
            font-family: monospace;
        }
        .ql-font-sans-serif {
            font-family: sans-serif;
        }

        /* Espaçamentos */
        .ql-size-small {
            font-size: 0.75em;
        }
        .ql-size-large {
            font-size: 1.5em;
        }
        .ql-size-huge {
            font-size: 2.5em;
        }

        /* Ajustes finos de espaçamento */
        .ql-indent-1 {
            margin-left: 20px;
        }
        .ql-indent-2 {
            margin-left: 40px;
        }
        .ql-indent-3 {
            margin-left: 60px;
        }
        .ql-indent-4 {
            margin-left: 80px;
        }

        iframe {
            width: 100%;
            min-height: 300px;
        }
        img {
            display: flex;
            align-self: center;
            justify-self: center;
            max-width: 70%;
            cursor: pointer;
        }

        @media screen and (max-width: 720px) {
            img {
                max-width: 100% !important;
            }
        }
    `,

    ImgHeader: styled.div<{ img: string }>`
        background-image: url(${props => props.img});
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 320px;
        position: relative; /* Permite que elementos filhos com absolute fiquem dentro */
        display: flex;
        align-items: center;

        @media screen and (max-width: 1280px) {
            height: 240px;
        }
    `,

    HeaderTitle: styled.h1`
        position: absolute; /* Agora ele está dentro do ImgHeader */
        left: 200px;
        font-family: 'Barlow';
        font-size: 38px;
        font-weight: 700;
        line-height: 43.2px;
        text-align: left;
        color: ${Colors.Blue};
        max-width: calc(52% - 40px); /* Para evitar que saia da tela */

        @media screen and (max-width: 1668px) {
            left: 20px;
            margin-left: 10%;
            max-width: calc(55% - 20px);
        }
        @media screen and (max-width: 720px) {
            line-height: 24px;
            font-size: 22px;
            left: 0px;
            max-width: calc(55% - 20px);
        }
    `,
};

export const conicColors: ProgressProps['strokeColor'] = {
    '0%': '#d34710',
    '50%': '#ffe58f',
    '100%': '#0bd837',
};

const FadConentsPage = () => {
    const {
        contentView,
        coursePublic,
        moduleView,
        setModuleView,
        setContentView,
        setCoursePublic,
        scrollViewContent,
        classSelected,
        setScrollViewContent,
        inscrito,
        isCourseCloseDate,
        avaliationContent,
        handleAvaliationContent,
        attComponentsByModules,
        handleClickCertificado,
    } = useContext(FerramentaAutoriaContext.Context);

    const { page, curso }: any = useParams();

    const [open, setOpen] = useState<boolean>(false);
    const history = useHistory();

    const [modalOpen, setModalOpen] = useState(false);
    const [currentImgSrc, setCurrentImgSrc] = useState('');

    const [visibleSections, setVisibleSections] = useState<number[]>([0]);
    const [firstNextInformationIndex, setFirstNextInformationIndex] = useState<number | null>(null); // Índice do primeiro NEXT_INFORMATION
    const [nextInformationIndexHidden, setNextInformationIndexHidden] = useState<number[]>([]);
    const [modalFinishCourse, setOpenModalFinishCourse] = useState(false);

    //loaders
    const [loadingButtonNext, setLoadingButtonNext] = useState<boolean>(false);

    const [porcent, setPorcent] = useState<number>(80);

    const [completionPercentage, setCompletionPercentage] = useState(0);

    const is700x = IsCustomMaxWidth(700);

    const refs = useRef<{ [key: number]: HTMLDivElement | null }>({});
    const componentRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});

    const serviceContent = new FadUserProgressContentsService();
    const serviceUserConcluidModules = new FadUserConcluidModulesService();
    const serviceFadAvaliations = new FadAvaliationsService();
    const serviceFadAvaliationsComponent = new FadAvaliationsComponentService();

    const viewContent = (ref: any) => {
        const contentCode = ref.getAttribute('data-code');

        // Garantir que contentCode seja tratado como número
        const contentCodeNumber = contentCode ? parseInt(contentCode, 10) : null;

        // Verificar se o código do conteúdo foi encontrado
        const content = coursePublic.modulos.find(m => m.codigo === moduleView?.codigo)?.conteudos.find(c => c.codigo === contentCodeNumber);

        if (content) {
            setContentView(content);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            Object.values(refs.current).forEach(targetElement => {
                if (targetElement) {
                    const rect = targetElement.getBoundingClientRect();

                    // Verifica se o elemento está visível na viewport
                    const isVisible = rect.top < window.innerHeight && rect.bottom > 0;

                    if (isVisible) {
                        viewContent(targetElement);
                    }
                }
            });
        };

        // Adiciona o evento de rolagem
        window.addEventListener('scroll', handleScroll);

        // Chama a função uma vez para capturar o estado inicial
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const saveListFadAvaliationsComponent = async (listModel: FadAvaliationsComponentModel[]) => {
        await serviceFadAvaliationsComponent.insertListModel(listModel);
    };

    const saveAvaliationsComponent = async (model: FadAvaliationsModel, codigoComp: number, listModel?: FadAvaliationsComponentModel[]) => {
        const { data, status } = await serviceFadAvaliations.create(model);

        if (status === 201) {
            setCoursePublic((prev: any) => {
                // Faz uma cópia do estado atual
                const updatedModules = prev.modulos.map((module: FadModulesPublicModel) => {
                    if (module.codigo === moduleView?.codigo) {
                        return {
                            ...module,
                            conteudos: module.conteudos.map((conteudo: FadContentsPublicModel) => {
                                if (conteudo.codigo === contentView?.codigo) {
                                    return {
                                        ...conteudo,
                                        componentes: conteudo.componentes.map((component: ComponentInteractionModel) => {
                                            if (component.id === codigoComp) {
                                                return { ...component, userAvaliation: data };
                                            }
                                            return component; // Mantém os outros componentes inalterados
                                        }),
                                    };
                                }
                                return conteudo;
                            }),
                        };
                    }
                    return module;
                });

                return {
                    ...prev,
                    modulos: updatedModules,
                };
            });
        }
    };

    const handleHotJar = () => {
        if (isProduction() && hotjar.initialized()) {
            if (curso === 'se-liga-na-alfabetizacao') {
                hotjar.event('feedback_SL');
            }

            if (curso === 'acelera_brasil') {
                hotjar.event('feedback_AC');
            }
        }

        setOpenModalFinishCourse(true);
    };

    const concluirContent = async (content: FadContentsPublicModel, currentIndex: number, concluidCourse?: boolean) => {
        const notCompleted = content?.progress?.isCompleted;

        setScrollViewContent(true);

        const classe = classSelected?.codigo ?? -1;

        const body = new FadUserProgressContentsPublicModel().fromJSON({
            codigo: content?.progress?.codigo ?? -1,
            codigoContent: content.codigo,
            codigoClasse: classe,
            isCompleted: true,
            percentageCompleted: 100,
        });

        content.progress = body;

        const nextContent = coursePublic.modulos
            .find(m => m.codigo === moduleView?.codigo)
            ?.conteudos.find((c, index) => index === currentIndex + 1);

        if (contentView && notCompleted) {
            if (nextContent) {
                return setContentView(nextContent);
            } else {
                return concluirModule();
            }
        }

        const { data, status } = await serviceContent.inserirProgress(body);

        if (status == 201) {
            if (nextContent) {
                setContentView(nextContent);
            }

            setCoursePublic((prev: any) => {
                // Faz uma cópia do estado atual
                const updatedModules = prev.modulos.map((module: FadModulesPublicModel) => {
                    // Verifica se é o módulo que queremos atualizar
                    if (module.codigo === moduleView?.codigo) {
                        return {
                            ...module,
                            conteudos: module.conteudos.map((conteudo: FadContentsPublicModel) => {
                                // Atualiza o conteúdo específico
                                if (conteudo.codigo === content.codigo) {
                                    return { ...content, progress: body }; // Substitui o conteúdo
                                }
                                return conteudo; // Mantém os outros conteúdos
                            }),
                        };
                    }
                    return module; // Mantém os outros módulos
                });

                // Retorna o novo estado
                return {
                    ...prev,
                    modulos: updatedModules,
                };
            });

            if (!nextContent) {
                const content = contentView;

                if (content) {
                    const newContent = new FadContentsPublicModel().fromJSON({
                        ...content,
                        progress: body,
                    });

                    setContentView(newContent);
                }

                concluirModule();
            }
        }
        setLoadingButtonNext(false);
        setScrollViewContent(false);
    };

    const openModal = (imgSrc: string) => {
        setCurrentImgSrc(imgSrc);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setCurrentImgSrc('');
    };

    const getTotalContents = () => {
        return moduleView?.conteudos.length;
    };

    const getModulePost = () => {
        const moduloAtualIndex = coursePublic.modulos.findIndex(m => m.codigo === moduleView?.codigo);

        const moduloPosterior = coursePublic.modulos[moduloAtualIndex + 1];

        const temModuloPosterior = moduloPosterior !== undefined;

        if (temModuloPosterior) {
            return moduloPosterior;
        }

        return null;
    };

    const getPostModuleExistent = () => {
        if (getModulePost() != null) {
            return true;
        }

        return false;
    };

    const getModuleLast = () => {
        const moduloAtualIndex = coursePublic.modulos.findIndex(m => m.codigo === moduleView?.codigo);

        const moduloAnterior = coursePublic.modulos[moduloAtualIndex - 1];

        const temModuloAnterior = moduloAnterior !== undefined;

        if (temModuloAnterior) {
            return moduloAnterior;
        }

        return null;
    };

    const getLastModuleExistent = () => {
        if (getModuleLast() != null) {
            return true;
        }

        return false;
    };

    const getModuleComplete = () => {
        const module = coursePublic.modulos.find(m => m.codigo === moduleView?.codigo);

        if (module) {
            if (module.progress) {
                return true;
            }
        }

        return false;
    };

    const validtedAllModulesConcluids = (): boolean => {
        const newListModules = coursePublic.modulos.filter(m => m.codigo !== moduleView?.codigo);

        return newListModules.every(m => m.progress);
    };
    const validtedAllContentsConcluids = (): boolean => {
        // Itera sobre os módulos e verifica se todos os conteúdos (exceto o conteúdo atual) estão concluídos
        return coursePublic.modulos.every(m =>
            m.conteudos.every(
                c => c.codigo === contentView?.codigo || c.progress.isCompleted // Ignora o conteúdo atual
            )
        );
    };

    const validtedAllModulesConcluidsFinish = (): boolean => {
        if (coursePublic.modulos.length === 0) {
            return true;
        }

        const newListModules = coursePublic.modulos;

        return newListModules.every(m => m.progress);
    };

    const validtedAllComponentsObrigatorys = (): boolean => {
        let valited: boolean = true;

        coursePublic.modulos?.forEach((m: FadModulesPublicModel) => {
            m.conteudos?.forEach((c: FadContentsPublicModel) => {
                c?.componentes
                    ?.filter(
                        cp =>
                            cp.obrigatory &&
                            [
                                ComponentInteractionEnum.QUIZ,
                                ComponentInteractionEnum.QUIZ_PONTUACAO,
                                ComponentInteractionEnum.REGUA_AVALIATIVA,
                                ComponentInteractionEnum.CORRESPONDENT,
                            ].includes(cp.tipo)
                    )
                    ?.forEach(cp => {
                        if (!cp.userAvaliation) {
                            valited = false;
                        }
                    });
            });
        });

        return valited;
    };

    const getTitleModulePost = () => {
        const moduleIndex = coursePublic.modulos.findIndex(m => m.codigo === moduleView?.codigo);

        const moduleNext = coursePublic.modulos[moduleIndex + 1];

        if (moduleView && moduleNext) {
            return moduleNext.title;
        }

        return null;
    };

    const concluirModule = async () => {
        if (moduleView && classSelected) {
            if (moduleView.progress) {
                if (getPostModuleExistent()) {
                    const modulePost = getModulePost();

                    if (modulePost) {
                        await attComponentsByModules(modulePost.codigo);

                        setModuleView(modulePost);
                        setContentView(modulePost.conteudos[0]);
                        return;
                    }
                }
            }

            const body = new FadUserConcluidModulesPublicModel().fromJSON({
                codigo: -1,
                codigoFerramentaAutoriaModule: moduleView.codigo,
                codigoFerramentaAutoriaClasse: classSelected.codigo,
            });

            const { status } = await serviceUserConcluidModules.create(body);

            if (status === 201) {
                if (getModulePost()) {
                    const moduloAtualIndex = coursePublic.modulos.findIndex(m => m.codigo === moduleView?.codigo);

                    const moduloPosterior = coursePublic.modulos[moduloAtualIndex + 1];

                    const temModuloPosterior = moduloPosterior !== undefined;

                    if (temModuloPosterior) {
                        await attComponentsByModules(moduloPosterior.codigo);
                        setModuleView(moduloPosterior);
                        setContentView(moduloPosterior.conteudos[0]);
                    }
                }
            }
        }
    };

    const validateAccess = () => {
        if (!inscrito) {
            return history.replace('/cursos/' + page + '/' + curso);
        }

        if (inscrito && classSelected && classSelected.closingDate) {
            const close = isCourseCloseDate(classSelected.closingDate.toString());

            if (close) {
                return history.replace('/cursos/' + page + '/' + curso);
            }
        }
    };

    const handleNextInformationHidden = (currentIndex: number) => {
        setNextInformationIndexHidden((prev: number[]) => [...prev, currentIndex]);
    };

    const handleNextInformationClick = (currentIndex: number) => {
        // Identifica os índices das seções que devem ser desbloqueadas
        if (contentView) {
            const totalComponents = contentView.componentes.length;

            // Filtrar os componentes visíveis (com base no índice presente em visibleSections)
            const visibleComponents = visibleSections.filter(
                index => contentView.componentes[index] // Certifica-se de que o índice está dentro do contentView.componentes
            ).length;

            // Calcula a porcentagem de conclusão
            const newCompletionPercentage = Math.min((visibleComponents / totalComponents) * 100, 100);

            // Condição para marcar como 100% apenas se todos os componentes forem visíveis
            const isComplete = visibleComponents === totalComponents;

            setCompletionPercentage(isComplete ? 100 : newCompletionPercentage);

            const nextVisibleSections: number[] = [];
            let nextComponentId: number | null = null;

            for (let i = currentIndex + 1; i < contentView.componentes.length; i++) {
                const component = contentView.componentes[i];
                nextVisibleSections.push(i);

                // Guarda o ID do próximo componente para rolar até ele
                if (!nextComponentId) {
                    nextComponentId = component.id;
                }

                if (component.tipo === ComponentInteractionEnum.NEXT_INFORMATION) {
                    break;
                }
            }

            setVisibleSections(prev => [...prev, ...nextVisibleSections]);

            const SCROLL_MARGIN_TOP = 100;

            requestAnimationFrame(() => {
                setTimeout(() => {
                    // Seleciona todos os novos componentes visíveis
                    const visibleElements = nextVisibleSections
                        .map(index => componentRefs.current[contentView.componentes[index].id])
                        .filter(el => el !== undefined);

                    if (visibleElements.length > 0) {
                        const firstElement = visibleElements[0]; // Primeiro novo componente visível
                        const lastElement = visibleElements[visibleElements.length - 1]; // Último novo componente visível

                        if (firstElement && lastElement) {
                            const firstElementRect = firstElement.getBoundingClientRect();
                            const lastElementRect = lastElement.getBoundingClientRect();
                            const windowHeight = window.innerHeight;

                            // Calcula a altura total dos novos componentes visíveis
                            const totalNewContentHeight = lastElementRect.bottom - firstElementRect.top;

                            if (totalNewContentHeight < windowHeight) {
                                // Se os novos componentes ocupam menos espaço que a tela, alinhar o último com o final da tela
                                window.scrollTo({
                                    top: window.scrollY + lastElementRect.bottom - windowHeight,
                                    behavior: 'smooth',
                                });
                            } else {
                                // Se os novos componentes forem grandes, rola até o primeiro
                                const topPosition = firstElementRect.top + window.scrollY - SCROLL_MARGIN_TOP;

                                window.scrollTo({
                                    top: topPosition,
                                    behavior: 'smooth',
                                });
                            }
                        }
                    }
                }, 200); // Pequeno atraso para garantir que os elementos estejam renderizados antes do scroll
            });
        }
    };

    const areAllComponentsVisible = (): boolean => {
        if (!contentView || !contentView.componentes) return false;

        const firstNextIndex = contentView.componentes.findIndex(component => component.tipo === ComponentInteractionEnum.NEXT_INFORMATION);

        const totalComponents = contentView.componentes.length;

        if (totalComponents === 0 || firstNextIndex === -1) {
            return true;
        }

        return visibleSections.length === totalComponents;
    };

    const contentConcluid = (): boolean => {
        if (contentView && contentView.progress && contentView.progress.isCompleted) {
            return true;
        }

        return false;
    };

    function isScreenSmallerThan(width: number) {
        return window.innerWidth < width;
    }

    const getPorcentAproveitament = (): number => {
        let totalComponentsAvaliations = 0;
        let somaNotaTotal = 0;

        coursePublic.modulos.forEach((m: FadModulesPublicModel) => {
            m.conteudos.forEach((c: FadContentsPublicModel) => {
                c.componentes
                    ?.filter(
                        cp =>
                            cp.obrigatory &&
                            [
                                ComponentInteractionEnum.QUIZ,
                                ComponentInteractionEnum.QUIZ_PONTUACAO,
                                ComponentInteractionEnum.REGUA_AVALIATIVA,
                                ComponentInteractionEnum.CORRESPONDENT,
                            ].includes(cp.tipo)
                    )
                    .forEach(cp => {
                        totalComponentsAvaliations++;
                        if (cp.userAvaliation) {
                            somaNotaTotal += cp.userAvaliation.average;
                        }
                    });
            });
        });

        return totalComponentsAvaliations > 0 ? somaNotaTotal / totalComponentsAvaliations : 100;
    };

    useEffect(() => {
        if (!isScreenSmallerThan(720)) {
            setOpen(true);
        }
    }, []);

    useEffect(() => {
        if (contentView && contentView.componentes) {
            setLoadingButtonNext(false);

            const firstNextIndex = contentView.componentes.findIndex(component => component.tipo === ComponentInteractionEnum.NEXT_INFORMATION);

            if (firstNextIndex !== -1) {
                setFirstNextInformationIndex(firstNextIndex);

                // Torna visíveis todos os componentes antes do primeiro NEXT_INFORMATION e o próprio NEXT_INFORMATION
                const initialVisibleSections = Array.from({ length: firstNextIndex + 1 }, (_, i) => i);
                setVisibleSections(initialVisibleSections);
            } else {
                setFirstNextInformationIndex(null);
            }
        }
    }, [contentView]);

    useEffect(() => {
        validateAccess();
    }, [moduleView, contentView]);

    useEffect(() => {
        setNextInformationIndexHidden([]);

        window.scrollTo(0, 0);
    }, [, contentView]);

    useEffect(() => {
        validateAccess();
    }, []);

    const goToBack = () => {
        history.replace('/cursos/' + page + '/' + curso);
    };

    return (
        <Styled.Container>
            <FadConentMenuLateralPublic open={open} setOpen={setOpen} porcentContentAtt={completionPercentage} />
            <Styled.Contents menuOpen={open}>
                {coursePublic &&
                    coursePublic.modulos.length > 0 &&
                    coursePublic.modulos
                        .find(m => m.codigo === moduleView?.codigo)
                        ?.conteudos.sort((a, b) => a.ordem - b.ordem)
                        .map((contents, index) => {
                            // Verifica se o módulo atual tem um próximo módulo

                            // Comentário: Aqui verificamos se existe um conteúdo anterior
                            const conteudoAnterior = coursePublic.modulos
                                .find(m => m.codigo === moduleView?.codigo)
                                ?.conteudos.slice(0, index) // Pega todos os conteúdos anteriores
                                .reverse() // Inverte para buscar do mais recente ao mais antigo
                                .find(c => c.codigo !== contents.codigo); // Garante que não é o mesmo código

                            // Verifica se o próximo conteúdo existe
                            const proximoConteudo =
                                coursePublic.modulos
                                    .find(m => m.codigo === moduleView?.codigo) // Encontra o módulo atual
                                    ?.conteudos.slice(index + 1) // Pega os conteúdos posteriores ao índice atual
                                    .find(c => c.codigo !== contents.codigo) || null; // Garante que não pega o mesmo conteúdo

                            // Comentário: Verificamos se o conteúdo anterior está concluído
                            const isConteudoAnteriorConcluido = conteudoAnterior
                                ? conteudoAnterior?.progress?.isCompleted || !coursePublic.course.sequentialContents
                                : true; // Se não existir conteúdo anterior, assume-se que é concluído.

                            if ((isConteudoAnteriorConcluido || index === 0) && contents.codigo === contentView?.codigo) {
                                return (
                                    <div key={index} ref={el => (refs.current[contents.codigo] = el)} data-code={contents.codigo}>
                                        {index !== 0 && conteudoAnterior && (
                                            <>
                                                <FAD_Button_Outline
                                                    styles={{ marginBottom: 22, width: '100%' }}
                                                    icon={<MdArrowUpward />}
                                                    text={`${conteudoAnterior.title} / ( ${contents.ordem + 1} - ${getTotalContents()} )`}
                                                    onClick={() => {
                                                        const indexCurrent = moduleView?.conteudos.findIndex(c => c.codigo === contents.codigo);
                                                        if (moduleView && indexCurrent) {
                                                            setContentView(moduleView?.conteudos[indexCurrent - 1]);
                                                        }
                                                    }}
                                                />
                                            </>
                                        )}

                                        {index === 0 && getLastModuleExistent() && (
                                            <>
                                                <FAD_Button_Outline
                                                    styles={{ marginBottom: 22, width: '100%' }}
                                                    icon={<MdArrowUpward />}
                                                    text={`${getModuleLast()?.title}`}
                                                    onClick={() => {
                                                        const modulo = getModuleLast();
                                                        if (modulo) {
                                                            setModuleView(modulo);

                                                            // Pegar o ultimo conteúdo do módulo.
                                                            const lastContent = modulo.conteudos.at(-1);
                                                            if (lastContent) {
                                                                setContentView(lastContent);
                                                            }
                                                        }
                                                    }}
                                                />
                                            </>
                                        )}

                                        <div style={{ position: 'relative' }} ref={el => (refs.current[contents.codigo] = el)}>
                                            <Styled.ImgHeader img={fetchStorageFileAuthoringTools(moduleView?.anexo.filepath ?? '')}>
                                                <Styled.HeaderTitle>{contents.title}</Styled.HeaderTitle>
                                            </Styled.ImgHeader>
                                        </div>

                                        {contents.componentes &&
                                            contents.componentes
                                                .sort((a, b) => a.ordem - b.ordem)
                                                .map((components, indexComponent) => {
                                                    const componentRef = (el: HTMLDivElement | null) => {
                                                        componentRefs.current[components.id] = el;
                                                    };

                                                    const existNextInformation = contentView.componentes.some(
                                                        (c: ComponentInteractionModel) => c.tipo === ComponentInteractionEnum.NEXT_INFORMATION
                                                    );

                                                    let existComponentObrigatorysNotUserValidation = true;

                                                    coursePublic.modulos.forEach(m => {
                                                        m.conteudos.forEach(c => {
                                                            if (c.codigo === contentView.codigo) {
                                                                c.componentes
                                                                    .sort((a, b) => a.ordem - b.ordem)
                                                                    .forEach(cc => {
                                                                        const visible = visibleSections.includes(cc.ordem);
                                                                        if (
                                                                            visible &&
                                                                            cc.obrigatory &&
                                                                            [
                                                                                ComponentInteractionEnum.QUIZ,
                                                                                ComponentInteractionEnum.QUIZ_PONTUACAO,
                                                                                ComponentInteractionEnum.REGUA_AVALIATIVA,
                                                                                ComponentInteractionEnum.CORRESPONDENT,
                                                                            ].includes(cc.tipo) &&
                                                                            !cc.userAvaliation
                                                                        ) {
                                                                            existComponentObrigatorysNotUserValidation = false;
                                                                        }
                                                                    });
                                                            }
                                                        });
                                                    });

                                                    // Verifica se a seção atual está visível
                                                    const isVisible = visibleSections.includes(indexComponent);

                                                    // Não renderiza o componente se ele não estiver visível

                                                    //!contentConcluid() && existNextInformation (Caso for voltar a regra de se caso estiver concluido o conteudo não apresentar o botão continuar e exibir todos os componentes)
                                                    if (!isVisible && existNextInformation) {
                                                        return null;
                                                    }

                                                    if (components.tipo === ComponentInteractionEnum.AUDIO) {
                                                        return (
                                                            <div
                                                                style={{
                                                                    marginTop: 22,
                                                                    marginBottom: 22,
                                                                }}
                                                                key={components.id}
                                                                className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                                ref={componentRef}
                                                            >
                                                                <RecursoMediaSwitch anexoUrl={components.conteudo} />
                                                            </div>
                                                        );
                                                    }

                                                    if (components.tipo === ComponentInteractionEnum.TEXT) {
                                                        return (
                                                            <div
                                                                key={components.id}
                                                                style={{
                                                                    backgroundColor: components.cor ?? '',
                                                                    padding: 8,
                                                                    marginTop: 22,
                                                                    marginBottom: 22,
                                                                }}
                                                            >
                                                                <Styled.TextContent
                                                                    className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                                    ref={componentRef}
                                                                    onClick={e => {
                                                                        const target = e.target as HTMLImageElement;
                                                                        if (target.tagName === 'IMG') {
                                                                            openModal(target.src);
                                                                        }
                                                                    }}
                                                                    onMouseOver={e => {
                                                                        const target = e.target as HTMLImageElement;
                                                                        if (target.tagName === 'IMG') {
                                                                        }
                                                                    }}
                                                                    dangerouslySetInnerHTML={{ __html: components.conteudo ?? '' }}
                                                                />
                                                            </div>
                                                        );
                                                    }
                                                    if (components.tipo === ComponentInteractionEnum.NEXT_INFORMATION) {
                                                        return (
                                                            <div
                                                                key={components.id}
                                                                ref={componentRef}
                                                                className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                                style={{
                                                                    display: nextInformationIndexHidden.includes(indexComponent)
                                                                        ? 'none'
                                                                        : 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    margin: 22,
                                                                }}
                                                            >
                                                                <FAD_Button_NextInformation
                                                                    icon={!existComponentObrigatorysNotUserValidation ? <TbLock /> : ''}
                                                                    text="Continuar"
                                                                    disabled={!existComponentObrigatorysNotUserValidation}
                                                                    onClick={() => {
                                                                        handleNextInformationClick(indexComponent);
                                                                        handleNextInformationHidden(indexComponent);
                                                                    }}
                                                                    styles={{ width: 600 }}
                                                                />
                                                            </div>
                                                        );
                                                    }
                                                    if (components.tipo === ComponentInteractionEnum.TWO_TEXT) {
                                                        return (
                                                            <div
                                                                key={components.id}
                                                                style={{ display: 'flex' }}
                                                                className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                            >
                                                                <Styled.TextContent
                                                                    ref={componentRef}
                                                                    style={{ width: '50%', padding: '8px' }}
                                                                    onClick={e => {
                                                                        const target = e.target as HTMLImageElement;
                                                                        if (target.tagName === 'IMG') {
                                                                            openModal(target.src);
                                                                        }
                                                                    }}
                                                                    onMouseOver={e => {
                                                                        const target = e.target as HTMLImageElement;
                                                                        if (target.tagName === 'IMG') {
                                                                        }
                                                                    }}
                                                                    dangerouslySetInnerHTML={{ __html: components.conteudo ?? '' }}
                                                                />
                                                                <Styled.TextContent
                                                                    ref={componentRef}
                                                                    style={{ width: '50%', padding: '8px' }}
                                                                    onClick={e => {
                                                                        const target = e.target as HTMLImageElement;
                                                                        if (target.tagName === 'IMG') {
                                                                            openModal(target.src);
                                                                        }
                                                                    }}
                                                                    onMouseOver={e => {
                                                                        const target = e.target as HTMLImageElement;
                                                                        if (target.tagName === 'IMG') {
                                                                        }
                                                                    }}
                                                                    dangerouslySetInnerHTML={{ __html: components.conteudoTwo ?? '' }}
                                                                />
                                                            </div>
                                                        );
                                                    }
                                                    if (components.tipo === ComponentInteractionEnum.TEXT_IMG_LEFT) {
                                                        return (
                                                            <Styled.RowBettween
                                                                key={components.id}
                                                                className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                            >
                                                                {components.anexo && (
                                                                    <Image
                                                                        style={{
                                                                            maxWidth: '1200px',
                                                                        }}
                                                                        src={fetchS3ComponentInteraction(components.anexo.filepath)}
                                                                    />
                                                                )}

                                                                <Styled.TextContent
                                                                    style={{ margin: 0, width: '90%' }}
                                                                    ref={componentRef}
                                                                    onClick={e => {
                                                                        const target = e.target as HTMLImageElement;
                                                                        if (target.tagName === 'IMG') {
                                                                            openModal(target.src);
                                                                        }
                                                                    }}
                                                                    onMouseOver={e => {
                                                                        const target = e.target as HTMLImageElement;
                                                                        if (target.tagName === 'IMG') {
                                                                        }
                                                                    }}
                                                                    dangerouslySetInnerHTML={{ __html: components.conteudo ?? '' }}
                                                                />
                                                            </Styled.RowBettween>
                                                        );
                                                    }
                                                    if (components.tipo === ComponentInteractionEnum.TEXT_IMG_RIGHT) {
                                                        return (
                                                            <Styled.RowBettween
                                                                key={components.id}
                                                                className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                            >
                                                                <Styled.TextContent
                                                                    style={{ margin: 0, width: !open ? '89%' : '100%' }}
                                                                    ref={componentRef}
                                                                    onClick={e => {
                                                                        const target = e.target as HTMLImageElement;
                                                                        if (target.tagName === 'IMG') {
                                                                            openModal(target.src);
                                                                        }
                                                                    }}
                                                                    onMouseOver={e => {
                                                                        const target = e.target as HTMLImageElement;
                                                                        if (target.tagName === 'IMG') {
                                                                        }
                                                                    }}
                                                                    dangerouslySetInnerHTML={{ __html: components.conteudo ?? '' }}
                                                                />
                                                                {components.anexo && (
                                                                    <Image
                                                                        style={{
                                                                            maxWidth: '1200px',
                                                                        }}
                                                                        src={fetchS3ComponentInteraction(components.anexo.filepath)}
                                                                    />
                                                                )}
                                                            </Styled.RowBettween>
                                                        );
                                                    }

                                                    if (components.tipo === ComponentInteractionEnum.TEXT_HIGHLIGHTED) {
                                                        return (
                                                            <div
                                                                key={components.id}
                                                                style={{
                                                                    position: 'relative',
                                                                    width: '100%',
                                                                    minHeight: '300px', // Mantém a altura mínima de 300px
                                                                    display: 'flex',
                                                                    padding: '54px 12px',
                                                                    margin: '22px 0px',
                                                                    justifyContent: 'center',
                                                                    overflow: 'hidden', // Garante que a imagem não ultrapasse os limites do contêiner
                                                                }}
                                                                className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                            >
                                                                {/* Imagem de fundo */}
                                                                {components.anexo && (
                                                                    <div
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: 0,
                                                                            left: 0,
                                                                            width: '100%',
                                                                            height: '100%',
                                                                            backgroundImage: `url(${fetchS3ComponentInteraction(
                                                                                components.anexo.filepath
                                                                            )})`,
                                                                            backgroundSize: 'cover', // Mantém a proporção e cobre todo o contêiner
                                                                            backgroundPosition: 'center', // Centraliza a imagem
                                                                            backgroundRepeat: 'no-repeat', // Evita repetições
                                                                            zIndex: 0, // Mantém a imagem no fundo
                                                                        }}
                                                                    />
                                                                )}

                                                                {/* Texto sobre a imagem */}
                                                                <Styled.TextContent
                                                                    style={{
                                                                        margin: 0,
                                                                        width: '100%',
                                                                        position: 'relative', // Mantém o texto acima da imagem
                                                                        zIndex: 1, // Coloca o texto sobre a imagem
                                                                        color: 'white', // Ajusta a cor do texto para contraste
                                                                        borderRadius: '5px',
                                                                    }}
                                                                    ref={componentRef}
                                                                    onClick={e => {
                                                                        const target = e.target as HTMLImageElement;
                                                                        if (target.tagName === 'IMG') {
                                                                            openModal(target.src);
                                                                        }
                                                                    }}
                                                                    dangerouslySetInnerHTML={{ __html: components.conteudo ?? '' }}
                                                                />
                                                            </div>
                                                        );
                                                    }

                                                    if (components.tipo === ComponentInteractionEnum.SANFONA) {
                                                        return (
                                                            <ConfigProvider
                                                                key={components.id}
                                                                collapse={{
                                                                    style: {
                                                                        backgroundColor: components.cor ?? '',
                                                                        marginBottom: '22px',
                                                                    },
                                                                }}
                                                            >
                                                                <Collapse
                                                                    size="large"
                                                                    className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                                    ref={componentRef}
                                                                    items={components.sanfona?.map((sanfona, idxSanfona) => ({
                                                                        key: idxSanfona,
                                                                        label: (
                                                                            <h1
                                                                                style={{
                                                                                    color:
                                                                                        components.cor === Colors.Blue ||
                                                                                        components.cor === Colors.SoftBlue
                                                                                            ? Colors.White
                                                                                            : Colors.Blue,
                                                                                    fontWeight: 700,
                                                                                }}
                                                                            >
                                                                                {sanfona.titulo}
                                                                            </h1>
                                                                        ),
                                                                        children: (
                                                                            <>
                                                                                {sanfona.componentSanfonaConteudos?.map(
                                                                                    (conteudo, idxConteudo) => {
                                                                                        if (
                                                                                            conteudo.tipoConteudo ===
                                                                                            ComponentInteractionEnum.TEXT
                                                                                        ) {
                                                                                            return (
                                                                                                <Styled.TextContent
                                                                                                    style={{
                                                                                                        margin: 0,
                                                                                                        padding: 10,
                                                                                                    }}
                                                                                                    key={idxConteudo}
                                                                                                    onClick={e => {
                                                                                                        const target =
                                                                                                            e.target as HTMLImageElement;
                                                                                                        if (target.tagName === 'IMG') {
                                                                                                            openModal(target.src);
                                                                                                        }
                                                                                                    }}
                                                                                                    onMouseOver={e => {
                                                                                                        const target =
                                                                                                            e.target as HTMLImageElement;
                                                                                                        if (target.tagName === 'IMG') {
                                                                                                        }
                                                                                                    }}
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html: conteudo.conteudo ?? '',
                                                                                                    }}
                                                                                                />
                                                                                            );
                                                                                        }

                                                                                        if (
                                                                                            conteudo.tipoConteudo ===
                                                                                            ComponentInteractionEnum.AUDIO
                                                                                        ) {
                                                                                            return (
                                                                                                <RecursoMediaSwitch
                                                                                                    key={idxConteudo}
                                                                                                    anexoUrl={conteudo.conteudo}
                                                                                                />
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                )}
                                                                            </>
                                                                        ),
                                                                    }))}
                                                                    expandIcon={({ isActive }) => (
                                                                        <StyledCaretRightOutlined
                                                                            style={{
                                                                                color:
                                                                                    components.cor === Colors.Blue ||
                                                                                    components.cor === Colors.SoftBlue
                                                                                        ? Colors.White
                                                                                        : Colors.Blue,
                                                                            }}
                                                                            size={22}
                                                                            rotate={isActive ? 90 : 0}
                                                                        />
                                                                    )}
                                                                />
                                                            </ConfigProvider>
                                                        );
                                                    }

                                                    if (components.tipo === ComponentInteractionEnum.QUIZ) {
                                                        return (
                                                            <div
                                                                key={components.id}
                                                                ref={componentRef}
                                                                className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                            >
                                                                <QuizInteractionPublic
                                                                    quizzes={components.quiz ?? []}
                                                                    componente={components}
                                                                    openModal={openModal}
                                                                    setBlockConcluirConteudo={b => {}}
                                                                    handleSaveAvaliation={v => {
                                                                        saveAvaliationsComponent(v, components.id);
                                                                    }}
                                                                    handleSaveAvaliationComponents={v => saveListFadAvaliationsComponent(v)}
                                                                />
                                                            </div>
                                                        );
                                                    }

                                                    if (components.tipo === ComponentInteractionEnum.CORRESPONDENT) {
                                                        return (
                                                            <div
                                                                key={components.id}
                                                                ref={componentRef}
                                                                className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                            >
                                                                <CorrespondentPublic
                                                                    dados={components.correspondent}
                                                                    component={components}
                                                                    handleSaveAvaliation={v => {
                                                                        saveAvaliationsComponent(v, components.id);
                                                                    }}
                                                                    handleSaveAvaliationComponents={v => saveListFadAvaliationsComponent(v)}
                                                                />
                                                                ;
                                                            </div>
                                                        );
                                                    }

                                                    if (components.tipo === ComponentInteractionEnum.CARROSSEL) {
                                                        return (
                                                            <div
                                                                key={components.id}
                                                                ref={componentRef}
                                                                className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                            >
                                                                <CarrosselInteractionPublic dados={components.carrossel} />
                                                            </div>
                                                        );
                                                    }

                                                    if (components.tipo === ComponentInteractionEnum.ABAS) {
                                                        return (
                                                            <div
                                                                key={components.id}
                                                                ref={componentRef}
                                                                className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                            >
                                                                <ComponentAbasPublic dados={components.abas} openModal={openModal} />
                                                            </div>
                                                        );
                                                    }

                                                    if (components.tipo === ComponentInteractionEnum.CARD_BARALHO) {
                                                        return (
                                                            <div
                                                                key={components.id}
                                                                ref={componentRef}
                                                                className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                            >
                                                                <ComponentBaralhoPublic
                                                                    dados={components.baralho ?? []}
                                                                    quantityVisible={components.quantityVisible}
                                                                    centralized
                                                                    isFad
                                                                />
                                                            </div>
                                                        );
                                                    }

                                                    if (
                                                        components.tipo === ComponentInteractionEnum.REGUA_AVALIATIVA &&
                                                        components.reguaAvaliativa
                                                    ) {
                                                        return (
                                                            <div
                                                                key={components.id}
                                                                ref={componentRef}
                                                                className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                            >
                                                                <ReguaAvaliativaPublic
                                                                    dados={components.reguaAvaliativa}
                                                                    component={components}
                                                                    handleSaveAvaliation={v => {
                                                                        saveAvaliationsComponent(v, components.id);
                                                                    }}
                                                                />
                                                            </div>
                                                        );
                                                    }
                                                    if (components.tipo === ComponentInteractionEnum.TIMELINE && components.timelines) {
                                                        return (
                                                            <div
                                                                key={components.id}
                                                                ref={componentRef}
                                                                className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                            >
                                                                <ComponentTimeLinesPublic
                                                                    dados={components.timelines}
                                                                    color={components.cor ?? ''}
                                                                />
                                                            </div>
                                                        );
                                                    }
                                                    if (
                                                        components.tipo === ComponentInteractionEnum.QUIZ_PONTUACAO &&
                                                        components.quizPontuacao
                                                    ) {
                                                        return (
                                                            <div
                                                                key={components.id}
                                                                ref={componentRef}
                                                                className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                            >
                                                                <ComponentQuizPontucaoPublic
                                                                    dados={components.quizPontuacao}
                                                                    component={components}
                                                                    handleSaveAvaliation={v => {
                                                                        saveAvaliationsComponent(v, components.id);
                                                                    }}
                                                                />
                                                            </div>
                                                        );
                                                    }
                                                    if (components.tipo === ComponentInteractionEnum.CENARIO && components.cenarios) {
                                                        return (
                                                            <div
                                                                key={components.id}
                                                                ref={componentRef}
                                                                className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                            >
                                                                <ComponentCenarioPublic dados={components.cenarios} />
                                                            </div>
                                                        );
                                                    }
                                                    if (
                                                        components.tipo === ComponentInteractionEnum.DIVIDER_LINE ||
                                                        components.tipo === ComponentInteractionEnum.DIVIDER_LINE_WITH_NUMBER
                                                    ) {
                                                        return (
                                                            <div
                                                                key={components.id}
                                                                ref={componentRef}
                                                                className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                            >
                                                                <ComponentDividerPublic dados={components} />
                                                            </div>
                                                        );
                                                    }
                                                    if (components.tipo === ComponentInteractionEnum.MARK_TEXT && components.marks) {
                                                        return (
                                                            <div
                                                                key={components.id}
                                                                ref={componentRef}
                                                                className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                            >
                                                                <ComponentMarkPublic dados={components} />
                                                            </div>
                                                        );
                                                    }

                                                    if (components.tipo === ComponentInteractionEnum.HOSTPOT && components.hostpots) {
                                                        return (
                                                            <div
                                                                key={components.id}
                                                                ref={componentRef}
                                                                className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                            >
                                                                <ComponentHostpotPublic dados={components} />
                                                            </div>
                                                        );
                                                    }
                                                })}
                                        {areAllComponentsVisible() && (
                                            <>
                                                <FadRaterCommentary
                                                    key={`rater-${contents.codigo}`}
                                                    rate={avaliationContent ? avaliationContent.rate : 0}
                                                    setRate={v => handleAvaliationContent(v)}
                                                    titleRate="Avalie este conteúdo"
                                                />

                                                {/* Colocar a logica caso não tenha proximo conteudo verificar se possui um proximo modulo */}
                                                {proximoConteudo && (
                                                    <>
                                                        <FAD_Button_Outline
                                                            disabled={loadingButtonNext}
                                                            loading={loadingButtonNext}
                                                            styles={{ marginBottom: 22, width: '100%', zIndex: 2 }}
                                                            icon={<MdArrowDownward />}
                                                            text={`${proximoConteudo.title} / ( ${contents.ordem + 1} - ${getTotalContents()} )`}
                                                            onClick={() => {
                                                                setLoadingButtonNext(true);
                                                                concluirContent(contents, index);
                                                            }}
                                                        />
                                                    </>
                                                )}

                                                {!proximoConteudo && getPostModuleExistent() && (
                                                    <>
                                                        <FAD_Button_Outline
                                                            disabled={loadingButtonNext}
                                                            loading={loadingButtonNext}
                                                            icon={<MdArrowDownward />}
                                                            styles={{ marginBottom: 22, width: '100%', zIndex: 2 }}
                                                            text={`${getTitleModulePost()}`}
                                                            onClick={() => {
                                                                setLoadingButtonNext(true);
                                                                concluirContent(contents, index);
                                                            }}
                                                        />
                                                    </>
                                                )}

                                                {!proximoConteudo &&
                                                    !getPostModuleExistent() &&
                                                    !getModuleComplete() &&
                                                    validtedAllModulesConcluids() &&
                                                    !coursePublic.course.finished && (
                                                        <>
                                                            <FAD_Button_Outline
                                                                styles={{ marginBottom: 22, width: '100%', zIndex: 2 }}
                                                                text={`Concluir e aguardar os próximos conteúdos`}
                                                                onClick={() => concluirContent(contents, index)}
                                                            />
                                                        </>
                                                    )}

                                                {!proximoConteudo &&
                                                    !moduleView?.conteudos.find(c => c.codigo === contentView.codigo)?.progress?.isCompleted &&
                                                    !getPostModuleExistent() &&
                                                    !validtedAllModulesConcluidsFinish() &&
                                                    validtedAllContentsConcluids() &&
                                                    validtedAllComponentsObrigatorys() &&
                                                    coursePublic.course.finished && (
                                                        <>
                                                            <FAD_Button_Outline
                                                                styles={{ marginBottom: 22, width: '100%', zIndex: 2 }}
                                                                text={`Concluir o curso`}
                                                                onClick={() => {
                                                                    concluirContent(contents, index, true);
                                                                    handleHotJar();
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                            </>
                                        )}
                                        <AmpliarImgV2 show={modalOpen} onClose={closeModal} imgSrc={currentImgSrc} />
                                    </div>
                                );
                            }
                        })}
            </Styled.Contents>
            <Modal closable={false} width={700} open={modalFinishCourse} footer>
                <ConfigProvider
                    input={{
                        style: {
                            height: 72,
                            fontSize: 22,
                        },
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', padding: is700x ? '' : '28px 66px' }}>
                        <span style={{ color: Colors.SoftBlue, fontWeight: 500, fontFamily: 'Barlow', fontSize: 16 }}>
                            {coursePublic.course.name}
                        </span>
                        <h1
                            style={{
                                fontFamily: 'Barlow',
                                fontSize: 32,
                                color: Colors.Blue,
                                fontWeight: 'bold',
                            }}
                        >
                            Parabéns, você concluiu o curso!
                        </h1>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                            <p
                                style={{ maxWidth: '445px', fontSize: 22 }}
                                dangerouslySetInnerHTML={{
                                    __html: `
                         Você teve um aproveitamento de :
                            `,
                                }}
                            />
                            <Progress
                                size={'small'}
                                type="dashboard"
                                percent={getPorcentAproveitament() * 10}
                                strokeColor={conicColors}
                                format={percent => (percent === 100 ? '100%' : `${parseInt(percent?.toString() ?? '0')}%`)}
                            />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '16px', marginBottom: '10px' }}>
                            <p
                                style={{ maxWidth: '445px', fontSize: 14 }}
                                dangerouslySetInnerHTML={{
                                    __html: `
                                ${FeedBacks(coursePublic.course.codigo, getPorcentAproveitament() * 10)}
                            `,
                                }}
                            />
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                gap: '16px',
                                alignItems: 'center',
                                justifyContent: coursePublic.certificacao ? 'center' : 'start',
                                marginTop: 10,
                            }}
                        >
                            <ButtonHomeV2 onClick={() => goToBack()}>Página inicial do curso</ButtonHomeV2>
                            {coursePublic.certificacao && (
                                <ButtonHomeSolftBlueV2 onClick={() => handleClickCertificado()}>Emitir o certificado</ButtonHomeSolftBlueV2>
                            )}
                        </div>
                    </div>
                </ConfigProvider>
            </Modal>
        </Styled.Container>
    );
};

export default FadConentsPage;
