import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyledCartaoMultiSolucoes = {
    Container: styled.div<{showDescription?: boolean}>`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 320px;
        height:  320px;
        background-color: #ffffff;
        border-radius: 10px;
        overflow: hidden;
        border: 1px solid ${Colors.Grey13};
        gap: 1rem;

        @media screen and (min-width: 1641px) {
            width: 350px;
        }


        @media screen and (max-width: 800px) {
            width: 300px;
        }
    `,

    ContentImg: styled.div`
        width: 100%;
        height: 200px ;
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: cover;

        @media screen and (max-width: 1640px) {
            height: 160px;
        }
    `,

    Img: styled.img<{ width?: string; height?: string }>`
        width: ${({ width }) => (width ? width : '100%')};
        height: ${({ height }) => (height ? height : '100%')};
    `,

    Title: styled.h1`
        color: ${Colors.Blue};
        font-weight: 500;
    `,

    Description: styled.p`
        font-size: 12px;
        line-height: 14px;
    `,
};

export default StyledCartaoMultiSolucoes;
