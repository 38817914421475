import React, { useContext, useEffect } from 'react';
import { IsMobileBiggerWidth } from 'core/util/WindowSize';
import GlobalStyled from '../../AcessoExlusivo.style';
import { AcessoExclusivoContext } from 'pages/acessoExclusivo/context/AcessoExclusivoContext';
import Loading from 'components/Loading';
import OverviewExclusivo from '../components/OverviewExclusivo';
import CicloExclusivo from '../components/CicloExclusivo';
import gestaoLogo from './../../../../images/solutions/logo/gestaoLogo.png';

const GestaoPrivate = () => {
    const { loading, idsMyCategorias } = useContext(AcessoExclusivoContext.Context);
    const isMobile = IsMobileBiggerWidth();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <GlobalStyled.GlobalContainer isMobile={isMobile}>
            {loading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    <OverviewExclusivo
                        isBlue
                        title="Gestão Nota 10"
                        img="ExclusivoGestaoImagem1.jpg"
                        logo={gestaoLogo}
                        //arquivo={'anexo_storage_files_2839206562349981815.pdf_2024-11-27-1732735828100.pdf'}
                        nome_arquivo="Gestao_Visao_Geral"
                        description={`
                            O Gestão Nota 10 tem como objetivo promover o desenvolvimento de uma política educacional democrática e inclusiva a partir da construção de uma rede
                            integrada de escolas autônomas, que garantam o ingresso, a permanência e o sucesso dos alunos, geridas por diretores tecnicamente competentes e capacitados
                            a garantirem o alcance das metas de qualidade da aprendizagem dos alunos e melhoria dos indicadores educacionais. </br> </br>
                            Acesse o documento de visão geral para saber mais.
                        `}
                    />
                    <CicloExclusivo
                        title="Trilha Formativa Gestão Nota 10"
                        description={`
                                              A trilha formativa do Gestão Nota 10 apresenta os conteúdos da solução educacional de modo que os profissionais
                                              (técnicos da Secretaria de Educação, gestores regionais, coordenadores pedagógicos e docentes) se apropriem dos conteúdos essenciais para a implementação da proposta.
                                              Inicie agora a sua trilha formativa!
                                            `}
                        img="ExclusivoGestaoImagem2.jpg"
                        itens={[
                            {
                                title: 'Trilha Formativa Gestão Nota 10',
                                url: '',
                                isBlock: true,
                                titleIsBlock: `Essa trilha formativa não está disponível.`,
                            },
                        ]}
                    />
                </>
            )}
        </GlobalStyled.GlobalContainer>
    );
};

export default GestaoPrivate;
