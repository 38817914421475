import React, { useEffect, useState } from 'react';
import { AcessoExclusivoContext } from './AcessoExclusivoContext';
import { CategoriaSolucoesPEService } from 'core/http/service/acessosExclusivos/CategoriaSolucoesPE.service';
import keycloak from 'core/config/keycloak';
import TipoSolucoes from 'models/solucoes/TipoSolucoes';
import { useHistory } from 'react-router-dom';
import { CategoriaUsersPE } from 'core/http/service/acessosExclusivos/CategoriaUsersPE.service';

export interface IAcessoExlusivoProvider {}

export const getIdSolucao = (url: string): number => {
    switch (url) {
        case 'circuito-360':
            return 1;
        case 'gestao-nota-10':
            return 2;
        case 'se-liga':
            return 3;
        case 'acelera-brasil':
            return 4;
        case 'formula-da-vitoria-portugues':
            return 5;
        case 'formula-da-vitoria-matematica':
            return 5;
        case 'dialogos-socioemocionais':
            return 6;
        // case 'realizando-escolhas':
        //     return 6;
        default:
            return -1;
    }
};

const AcessoExlusivoProvider = ({ children }: React.PropsWithChildren<IAcessoExlusivoProvider>) => {
    const serviceCategoriaSolucoes = new CategoriaSolucoesPEService();
    const serviceUserCategoria = new CategoriaUsersPE();
    const [idsMyCategorias, setIdsMyCategorias] = useState<number[]>([]);

    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();

    const validarSePossuiAcesso = async () => {
        const link: string = location.pathname.split('/')[2];

        const idSolucao = getIdSolucao(link);

        if (keycloak.authenticated) {
            const response = await serviceCategoriaSolucoes.getSolucoesByUser();

            if (response.status === 200) {
                const solucoes: TipoSolucoes[] = response.data;

                const possui = solucoes.find(f => f.id === idSolucao);

                if (!possui) {
                    history.push('/solucoes');
                } else {
                    const { data, status } = await serviceUserCategoria.listMyCategories();

                    if (status === 200) {
                        data.map((categorias: any) => {
                            setIdsMyCategorias(prevState => [...prevState, categorias.codigoCategoria]);
                        });
                    }

                    setLoading(false);
                }
            }
        } else {
            history.push('/solucoes');
        }
    };

    useEffect(() => {
        validarSePossuiAcesso();
        setIdsMyCategorias([]);
    }, [location.pathname]);
    const context: AcessoExclusivoContext.IContext = {
        loading: loading,
        idsMyCategorias: idsMyCategorias,
    };

    return <AcessoExclusivoContext.Context.Provider value={context}>{children}</AcessoExclusivoContext.Context.Provider>;
};

export default AcessoExlusivoProvider;
