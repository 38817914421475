import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import React, { useEffect, useRef, useState } from 'react';
import { Layout, Menu, theme } from 'antd';
import Colors from 'enums/Colors';
import LogoEbook from './assets/AlfaTeoriaPratica/logo_ebook.png';
import { RiPagesLine } from 'react-icons/ri';
import ATP_Modulo01 from './modules/AlfaTeoriaPratica/ATP_Modulo01';
import AmpliarImg from 'components/ampliarImg/AmpliarImg';
import ATP_Modulo02 from './modules/AlfaTeoriaPratica/ATP_Modulo02';
import ATP_Modulo03 from './modules/AlfaTeoriaPratica/ATP_Modulo03';
import ATP_Modulo04 from './modules/AlfaTeoriaPratica/ATP_Modulo04';
import ATP_Modulo05 from './modules/AlfaTeoriaPratica/ATP_Modulo05';
import ATP_Modulo06 from './modules/AlfaTeoriaPratica/ATP_Modulo06';
import FirstCapa from './modules/AlfaTeoriaPratica/FirstCapa';
import AuthoresEbook from './modules/AlfaTeoriaPratica/AuthoresEbook';
import SummaryEbook, { CapituloEbook, TypeItemSummary } from './components/SummayEbook';
import ATP_Apresentacao from './modules/AlfaTeoriaPratica/ATP_Apresentacao';

//logo

import LogoIAS from './assets/AlfaTeoriaPratica/logo_IAS.png';

const { Header, Content, Footer, Sider } = Layout;

const navMenu = [
    {
        key: 100,
        label: 'Alfabetizar',
    },
    {
        key: 101,
        label: 'Instituto Ayrton Senna',
    },
    {
        key: 102,
        label: 'Sumário',
    },
    {
        key: 103,
        label: 'Apresentação',
    },
    {
        key: 1,
        label: '01 - Caminhos da alfabetização no Brasil: uma análise histórica',
    },
    {
        key: 2,
        label: '02 - Alfabetização e neurociências',
    },
    {
        key: 3,
        label: '03 - Educação infantil: a fase de cuidar do broto',
    },
    {
        key: 4,
        label: '04 - Alfabetização integral',
    },
    {
        key: 5,
        label: '05 - Práticas inspiradoras',
    },
    {
        key: 6,
        label: '06 - Para saber mais',
    },
];

const dadosSummary: CapituloEbook[] = [
    {
        indexModulo: 103,
        title: '',
        subTitle: 'Apresentação',
        moduloNumber: '05',
        description: 'Ana Carolina Zuanazzi',
        type: TypeItemSummary.TYPE_02,
    },
    {
        indexModulo: 1,
        title: 'Capítulo 01',
        subTitle: 'Caminhos da alfabetização no Brasil: uma análise histórica',
        moduloNumber: '08',
        description: `
            Beatriz Maria Campbell Alquéres, Brenda Prata, <br />
            Daine Zanon, Gabriela Cáceres, Inês Kisil Miskalo e <br />
            Silvia Aparecida de Jesus Lima <br />
        `,
        type: TypeItemSummary.TYPE_01,
    },
    {
        indexModulo: 2,
        title: 'Capítulo 02',
        subTitle: 'Alfabetização e neurociências',
        moduloNumber: '25',
        description: `
            Gisele Alves e Janaina Weissheimer
        `,
        type: TypeItemSummary.TYPE_01,
    },
    {
        indexModulo: 3,
        title: 'Capítulo 03',
        subTitle: 'Educação infantil: a fase de cuidar do broto',
        moduloNumber: '40',
        description: `
            Por que esta etapa da educação básica é essencial <br />
            para sedimentar as bases para a alfabetização <br />
            Mariana Luz
        `,
        type: TypeItemSummary.TYPE_01,
    },
    {
        indexModulo: 4,
        title: 'Capítulo 04',
        subTitle: 'Alfabetização integral',
        moduloNumber: '51',
        description: `
            Ângela Chuvas Naschold e Ana Carolina Zuanazzi
        `,
        type: TypeItemSummary.TYPE_01,
    },
    {
        indexModulo: 5,
        title: 'Capítulo 05',
        subTitle: 'Práticas inspiradoras',
        moduloNumber: '58',
        description: `
            Wanessa Ranielle Trajano Costa, Welen Freitas, Joana <br />
            Marques de Lima Saar Xavier e Vastí Ferrari Marques
        `,
        type: TypeItemSummary.TYPE_01,
    },
    {
        indexModulo: 6,
        title: 'Capítulo 06',
        subTitle: 'Para saber mais',
        moduloNumber: '94',
        description: `
         
        `,
        type: TypeItemSummary.TYPE_01,
    },
];

export interface IModuloPage {
    setImgAmpliar: (value: string) => void;
    scrollToDivLink?: (divRef: any, abaAcitve: number) => void;
    modulos: any[];
}

const AlfaTeoriaPratica = () => {
    const [navActive, setNavActive] = useState<number>(1);
    const [modalOpen, setModalOpen] = useState(false);
    const [porcent, setPorcent] = useState<number>(80);
    const [currentImg, setCurrentImg] = useState<string>('');

    const capa01 = useRef<any>(null);
    const author = useRef<any>(null);
    const sumario = useRef<any>(null);
    const apresentacao = useRef<any>(null);
    const modulo1 = useRef<any>(null);
    const modulo2 = useRef<any>(null);
    const modulo3 = useRef<any>(null);
    const modulo4 = useRef<any>(null);
    const modulo5 = useRef<any>(null);
    const modulo6 = useRef<any>(null);

    const scrollToDiv = (divRef: any, abaAcitve: number) => {
        divRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

        setNavActive(abaAcitve);
    };

    useEffect(() => {
        const options: IntersectionObserverInit = {
            root: null, // Observa na viewport
            rootMargin: '0px',
            threshold: 0.1, // Trigger quando 10% da div está visível
        };

        const callback: IntersectionObserverCallback = entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    console.log('🚀 ~ useEffect ~ entry.target.id:', entry.target.id);
                    switch (entry.target.id) {
                        case 'modulo1':
                            return setNavActive(1);
                        case 'modulo2':
                            return setNavActive(2);
                        case 'modulo3':
                            return setNavActive(3);
                        case 'modulo4':
                            return setNavActive(4);
                        case 'modulo5':
                            return setNavActive(5);
                        case 'modulo6':
                            return setNavActive(6);
                        case 'capa01':
                            return setNavActive(100);
                        case 'author':
                            return setNavActive(101);
                        case 'sumario':
                            return setNavActive(102);
                        case 'apresentacao':
                            return setNavActive(103);
                    }
                }
            });
        };

        const observer = new IntersectionObserver(callback, options);

        const refs = [capa01, author, sumario, apresentacao, modulo1, modulo2, modulo3, modulo4, modulo5, modulo6];
        refs.forEach(ref => {
            console.log('🚀 ~ useEffect ~ ref.current:', ref.current);
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        return () => {
            refs.forEach(ref => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            });
            observer.disconnect();
        };
    }, []);

    return (
        <div
            style={{
                paddingTop: '80px',
            }}
        >
            <AmpliarImg imgSrc={currentImg} onClose={() => setModalOpen(false)} porcent={porcent} setPorcent={setPorcent} show={modalOpen} />;
            <Layout style={{ height: '790px' }}>
                <Sider
                    style={{ backgroundColor: Colors.White }}
                    breakpoint="lg"
                    collapsedWidth="0"
                    onBreakpoint={broken => {
                        console.log(broken);
                    }}
                    onCollapse={(collapsed, type) => {
                        console.log(collapsed, type);
                    }}
                >
                    <div className="demo-logo-vertical">
                        <img style={{ width: '100%', borderRight: '1px solid #f0f0f0' }} src={LogoEbook} alt="Logo do Ebook" />
                    </div>
                    <Menu
                        theme="light"
                        mode="inline"
                        selectedKeys={[navActive.toString()]}
                        defaultSelectedKeys={['1']}
                        items={navMenu.map(item => ({
                            ...item,
                            onClick: () => {
                                if (item.key === 1) scrollToDiv(modulo1, 1);
                                if (item.key === 2) scrollToDiv(modulo2, 2);
                                if (item.key === 3) scrollToDiv(modulo3, 3);
                                if (item.key === 4) scrollToDiv(modulo4, 4);
                                if (item.key === 5) scrollToDiv(modulo5, 5);
                                if (item.key === 6) scrollToDiv(modulo6, 6);
                                if (item.key === 100) scrollToDiv(capa01, 100);
                                if (item.key === 101) scrollToDiv(author, 101);
                                if (item.key === 102) scrollToDiv(sumario, 102);
                                if (item.key === 103) scrollToDiv(apresentacao, 103);
                            },
                        }))}
                    />
                </Sider>
                <Layout>
                    <Header style={{ padding: 0, paddingLeft: 20, backgroundColor: Colors.White }}>


                        <h1
                            style={{
                                fontSize: 16,
                                fontWeight: 600,
                            }}
                        >
                            <span
                                style={{
                                    color: Colors.SoftBlue,
                                }}
                            >
                                ALFABETIZAR
                            </span>{' '}
                            : DAS DEFINIÇÕES ÀS BOAS PRÁTICAS
                        </h1>
                    </Header>
                    <Content style={{ margin: '24px 16px 0' }}>
                        <div
                            style={{
                                minHeight: 360,
                                height: '100%',
                                overflow: 'auto',
                                overflowX: 'hidden',
                                overflowY: 'scroll',
                            }}
                        >
                            <div ref={capa01} id="capa01">
                                <FirstCapa />
                            </div>
                            <div
                                style={{
                                    marginTop: 10,
                                    marginBottom: 10,
                                    height: 720,
                                    backgroundColor: '#00a7ec',
                                }}
                            />
                            <div ref={author} id="author">
                                <AuthoresEbook />
                            </div>
                            <div ref={sumario} id="sumario">
                                <SummaryEbook
                                    setImgAmpliar={() => ''}
                                    capitulos={dadosSummary}
                                    scrollToDivLink={scrollToDiv}
                                    modulos={[apresentacao, modulo1, modulo2, modulo3, modulo4, modulo5, modulo6]}
                                />
                            </div>
                            <div ref={apresentacao} id="apresentacao" />
                                <ATP_Apresentacao setImgAmpliar={() => ''} modulos={[]} />

                            <div ref={modulo1} id="modulo1" />
                            <ATP_Modulo01
                                setImgAmpliar={e => {
                                    setModalOpen(true);
                                    setCurrentImg(e);
                                }}
                                modulos={[]}
                            />

                            <div ref={modulo2} id="modulo2" />
                            <ATP_Modulo02
                                setImgAmpliar={e => {
                                    setModalOpen(true);
                                    setCurrentImg(e);
                                }}
                                modulos={[]}
                            />
                            <div ref={modulo3} id="modulo3" />
                            <ATP_Modulo03
                                setImgAmpliar={e => {
                                    setModalOpen(true);
                                    setCurrentImg(e);
                                }}
                                modulos={[]}
                            />
                            <div ref={modulo4} id="modulo4" />
                            <ATP_Modulo04
                                setImgAmpliar={e => {
                                    setModalOpen(true);
                                    setCurrentImg(e);
                                }}
                                modulos={[]}
                            />
                            <div ref={modulo5} id="modulo5" />
                            <ATP_Modulo05
                                setImgAmpliar={e => {
                                    setModalOpen(true);
                                    setCurrentImg(e);
                                }}
                                scrollToDivLink={scrollToDiv}
                                modulos={[modulo1, modulo2, modulo3, modulo4]}
                            />
                            <div ref={modulo6} id="modulo6" />
                            <ATP_Modulo06
                                setImgAmpliar={e => {
                                    setModalOpen(true);
                                    setCurrentImg(e);
                                }}
                                scrollToDivLink={scrollToDiv}
                                modulos={[]}
                            />
                            <div
                                style={{
                                    marginTop: 10,
                                    marginBottom: 10,
                                    height: 720,
                                    backgroundColor: '#00a7ec',
                                }}
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginTop: 10,
                                    marginBottom: 10,
                                    height: 720,
                                    backgroundColor: Colors.Blue,
                                }}
                            >
                                <img src={LogoIAS} alt="Logo IAS" />
                            </div>
                        </div>
                    </Content>
                    <Footer style={{ textAlign: 'center', fontSize: 12 }}>
                        Material produzido pelo Instituto Ayrton Senna | 2024 <br />
                        <br /> Pode ser reproduzido, desde que mantida a menção de autoria
                    </Footer>
                </Layout>
            </Layout>
        </div>
    );
};

export default AlfaTeoriaPratica;
